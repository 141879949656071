import { useHistory, useParams } from 'react-router-dom';
import { formConfigBuilder } from './FormConfigBuilder';
import FormWrapper from 'components/Form/FormWrapper';
import { H1, H3 } from 'components/Typography/Heading';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import { set, startOfDay } from 'date-fns';
import { useOktaAuth } from '@okta/okta-react';
import { useReactQueryNew } from 'utils/hooks/useReactQuery';
import axios, { isCustomAxiosError } from 'utils/axios';
import Loader from 'components/Loader';
import axiosGlobal from 'utils/axiosGlobal';
import { handleAxiosError } from 'utils/errorHelper';
import { mandatoryGSTFields, stateToStateCodes } from 'components/Form/utils';

type InvoicingPayload = {
  address?: AddressPayload & Partial<ManualAddressPayload>;
};

type AddressPayload = {
  first_name: string;
  last_name?: string;
  phone_number: number;
  gstin?: string;
};

type ManualAddressPayload = {
  address_line1: string;
  address_line2: string;
  state: string;
  pincode: string;
  city: string;
  company_name: string;
  state_code: string;
  country: string;
};

type VariantMetaType = {
  date: string;
  quantity?: number;
};

type InventoryMetaType = {
  date: string;
  booking_start_time: string;
  number_of_hours: number;
};

type EventMetaType = {
  security_deposit: number;
  lead: string;
  event_id: string;
};

type orderItemsPayload = {
  product_uid: string;
  product_pricing_uid: string;
  price: number;
  product_meta:
    | VariantMetaType
    | (InventoryMetaType &
        //make sure if it's event space then all fields are present.
        Partial<EventMetaType> &
        (
          | {}
          | {
              eventDate: Date;
              eventType: string;
              eventTime: string;
            }
        ))
    | null;
  building_uid: string;
};

type PayloadType = {
  customer_email: string;
  payment_mode: string;
  payment_type: string;
  order_items: orderItemsPayload[];
  invoicing_details?: InvoicingPayload;
  notes: string;
};

type PostOffice = {
  Name: string;
  Description: string;
  BranchType: string;
  DeliveryStatus: string;
  Circle: string;
  District: string;
  Division: string;
  Region: string;
  Block: string;
  State: string;
  Country: string;
  Pincode: string;
};
type ApiResponse = {
  Message: string;
  Status: string;
  PostOffice: PostOffice[] | null;
};

export type ProductResponseType = {
  product_uid: string;
  product_code: string;
  product_name: string;
  product_description: string;
  category_uid: string;
  category_name: string;
  fb_building_id?: string;
  global_uid?: string;
  building_name?: string;
  images: string[];
  meta_info:
    | {
        is_quantity_required?: boolean;
        can_add_multiple_items?: boolean;
        postpaid_invoice_allowed?: boolean;
        capacity?: number;
        partner_variant_id?: string;
        partner_product_id?: string;
        is_offline_payment_available?: boolean;
        lead_fields?: string[];
        has_deposit?: boolean;
      }
    | undefined;
  pricing: {
    product_pricing_uid: string;
    variant_name: string;
    selling_price: number;
    strike_through_price: number;
  }[];
};

const ProductPage = () => {
  const { productId }: { productId: string } = useParams();
  const { isLoading, isError, data: productData } = useReactQueryNew<
    ProductResponseType
  >(['admin', 'products', 'details', productId], {
    url: `/api/v1/admin/products/${productId}`,
  });
  const { push } = useHistory();
  const { setToastType, userInfo } = useAuthContext()!;
  const { authState } = useOktaAuth();

  if (isLoading) {
    return <Loader customHeight="75vh" />;
  }

  if (!productId || isError || !productData)
    //add no product details also here
    return (
      <H3 style={{ textAlign: 'center', color: 'red' }}>
        Product details not found, please try again after some time.
      </H3>
    );

  const formConfig = formConfigBuilder(
    productData.data,
    userInfo.is_postpaid_user,
  );

  const getPayload = (data: any, productData: any) => {
    const body: PayloadType = {
      customer_email: data.email,
      order_items: [],
      payment_mode: data['Payment Mode'] ? 'OFFLINE' : 'ONLINE',
      payment_type: !!data.postpaid ? 'POSTPAID' : 'PREPAID',
      notes: data.notes,
    };

    //if item has just pricing and it's not event invoicing
    if (
      productData?.pricing &&
      !productData?.meta_info?.can_add_multiple_items &&
      !productData?.global_uid
    ) {
      body.order_items.push({
        product_uid: productId,
        product_pricing_uid: data.pricing_id || data.product?.pricing_id,
        price: data.selling_price,
        product_meta: {
          date: startOfDay(new Date(data['Booking Date'])).toISOString(),
          quantity: productData?.meta_info?.is_quantity_required
            ? +data.quantity
            : 1,
          ...(productData?.meta_info?.has_deposit &&
            +data.security_deposit > 0 && {
              security_deposit: +data.security_deposit,
            }),
          ...(productData?.meta_info?.lead_fields?.length > 0 && {
            lead: data.leadSource,
            event_id: data.eventLeadId,
          }),
        },
        building_uid: productData.building_uid,
      });
    }

    //if event invoicing
    if (productData?.meta_info?.can_add_multiple_items) {
      data.multiEvent.forEach((item: any) => {
        body.order_items.push({
          product_uid: productId,
          product_pricing_uid: item.field1?.pricing_id,
          price: item.field2,
          product_meta: {
            date: startOfDay(new Date(data['Booking Date'])).toISOString(),
            quantity: 1,
          },
          building_uid: productData.building_uid,
        });
      });
    }

    //if global inventory
    if (productData?.global_uid) {
      const bookingDate = new Date(data['Booking Date']);
      const meetingTime = new Date(data['Meeting time'].value);

      // Set hours, minutes, and seconds on bookingDate based on meetingTime
      const bookingStartTime = set(bookingDate, {
        hours: meetingTime.getHours(),
        minutes: meetingTime.getMinutes(),
        seconds: meetingTime.getSeconds(),
      });

      body.order_items.push({
        product_uid: productId,
        product_pricing_uid: data.pricing_id,
        price: data.selling_price,
        product_meta: {
          start_time: bookingStartTime,
          number_of_hours: data.hours,
          ...(productData?.meta_info?.has_deposit &&
            +data.security_deposit > 0 && {
              security_deposit: +data.security_deposit,
            }),
          ...(productData?.meta_info?.lead_fields?.length > 0 && {
            lead: data['Lead source']?.value,
            event_id: data['Event lead Id'],
          }),
        },
        building_uid: data.building_uid,
      });
    }

    // invoicing related fields
    if (data.gstinToggle && data.gstin) {
      body.invoicing_details = {};
      body.invoicing_details.address = {
        first_name: data['First name'],
        last_name: data['Last name'],
        phone_number: data.number,
        gstin: data.gstin,
      };
    } else if (
      !data.gstinToggle &&
      mandatoryGSTFields.every(field => !!data[field as keyof typeof data])
    ) {
      body.invoicing_details = {};
      body.invoicing_details.address = {
        address_line1: data['Address Line 1'],
        state: data.state.value,
        pincode: data.pincode,
        city: data.city,
        first_name: data['First name'],
        last_name: data['Last name'],
        phone_number: data.number,
        ...(data['Address Line 2'] && {
          address_line2: data['Address Line 2'],
        }),
        ...(data['Company Name'] && {
          company_name: data['Company Name'],
        }),
        country: 'India',
        state_code: stateToStateCodes[data.state?.value],
      };
    }
    return body;
  };

  const getOnlineConferenceRoomPayload = (data: any) => {
    const bookingDate = new Date(data['Booking Date']);
    const meetingTime = new Date(data['Meeting time'].value);

    // Set hours, minutes, and seconds on bookingDate based on meetingTime
    const bookingStartTime = set(bookingDate, {
      hours: meetingTime.getHours(),
      minutes: meetingTime.getMinutes(),
      seconds: meetingTime.getSeconds(),
    });
    const draftNotesBody: any = {
      handle: 'conference-room-credit',
      building_title: productData.data?.building_name,
      building_id: productData.data?.fb_building_id,
      isDashboard: true,
    };
    const conferenceBody = {
      booking_date: bookingDate,
      booking_start_time: bookingStartTime,
      variant_title: productData.data?.meta_info?.capacity + ' seater',
      member_count: productData.data?.meta_info?.capacity,
      no_of_hrs: data.hours,
      building_title: productData.data?.building_name,
    };
    draftNotesBody.conference_rooms_info = [conferenceBody];

    const body: any = {
      line_items: [
        {
          variant_id: productData.data?.meta_info?.partner_variant_id,
          quantity: data.hours,
        },
      ],
      customer_email: data.email,
      notes: data.notes,
      order_creator: userInfo ? userInfo.id : null,
      buildingId: productData.data?.fb_building_id,
      draft_notes: JSON.stringify(draftNotesBody),
    };

    body.noOfHrs = data.hours;
    body.globalInventoryId = productData.data?.global_uid;
    body.order_type = 'B2C';
    body.userAnalytics = {
      productInfo: {
        product: 'conference room',
        created_at: new Date().getTime(),
      },
    };

    if (data.gstin) body.gstin = data.gstin;
    return body;
  };

  const onSubmit = async (data: any, setError: any) => {
    if (
      ((data.gstin !== '' && data.gstinToggle) ||
        (data.postpaid && data.gstinToggle && data.gstin !== '')) &&
      !data.validate
    ) {
      setError('gstin', {
        type: 'manual',
        message: 'Please validate GSTIN before proceeding',
      });
      return;
    }

    // check if pincode and state match
    if (data.pincode && data.state) {
      try {
        const response = await fetch(
          `https://api.postalpincode.in/pincode/${data.pincode}`,
        );
        const result: ApiResponse[] = await response.json();
        const postOfficeData = result?.[0]?.PostOffice;

        if (result?.[0]?.Status !== 'Success' || !postOfficeData) {
          setError('pincode', {
            type: 'manual',
            message: 'Please enter a valid pincode',
          });
          return;
        }

        const validState = postOfficeData.some(
          (postOffice: PostOffice) => postOffice.State === data.state.value,
        );

        if (!validState) {
          setError('pincode', {
            type: 'manual',
            message: `The pincode does not belong to ${data.state.value}`,
          });
          return;
        }
      } catch (err) {
        console.error('Error:', err);
        if (isCustomAxiosError(err)) {
          console.error(err);
          setToastType({
            show: true,
            type: 'error',
            message:
              err?.response?.data?.message ||
              'Something went wrong while validating pincode.',
          });
        }
      }
    }

    //for online conference room (myhq shopify product)
    if (productData.data?.meta_info?.partner_product_id) {
      if (!data['Meeting time'].value) {
        setToastType({
          show: true,
          type: 'error',
          message: 'Please select a timeslot before proceeding',
        });
        return;
      }
      const body = getOnlineConferenceRoomPayload(data);
      if (!body) return;
      try {
        await axiosGlobal.post('api/v1/admin/conference-rooms/book', body, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: authState?.accessToken?.accessToken,
          },
        });
        setToastType({
          show: true,
          type: 'success',
          message: 'Payment link sent to customer',
        });
        push('/shop-purchases');
      } catch (err) {
        console.error('Validation error:', err);
        if (isCustomAxiosError(err)) {
          console.error(err);
          setToastType({
            show: true,
            type: 'error',
            message:
              err?.response?.data?.message ||
              'Something went wrong while creating conference room order',
          });
        }
      }
      return;
    }

    //for OMS products
    const body: PayloadType = getPayload(data, productData.data);
    try {
      const response = await axios.post('/api/admin/orders', body, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
        },
        baseURL: process.env.REACT_APP_OMS_BASE_URL,
      });
      setToastType({
        show: true,
        type: 'success',
        message:
          response?.data?.message ||
          'Order created successfully. Please check your email for invoice.',
      });
      push('/shop-purchases');
    } catch (error) {
      const errorMessage = handleAxiosError(
        error,
        'Something went wrong while creating order',
      );
      setToastType({
        show: true,
        type: 'error',
        message: errorMessage,
      });
    }
  };

  const defaultValues: Record<string, any> = {
    gstinToggle: true,
    validate: false, // for gst validation
    'Building Name': productData.data?.building_name,
    ...(productData.data?.meta_info?.is_offline_payment_available && {
      'Payment Mode': false,
    }),
    ...(productData.data?.pricing.length === 1 && {
      pricing_id: productData.data?.pricing[0]?.product_pricing_uid,
      selling_price: productData.data?.pricing[0].selling_price,
    }),
    ...(productData.data?.meta_info?.can_add_multiple_items && {
      multiEvent: [{ field1: '', field2: '' }],
    }),
    ...(productData.data?.meta_info?.can_add_multiple_items && {
      multiEvent: [{ field1: '', field2: '' }],
    }),
    ...(productData.data?.meta_info?.is_quantity_required && {
      quantity: 1,
    }),
    ...(productData.data?.meta_info?.has_deposit && {
      security_deposit: 0,
    }),
  };

  return (
    <div>
      <H1>
        {productData.data?.product_name?.charAt(0).toUpperCase() +
          productData.data?.product_name?.slice(1)}{' '}
      </H1>
      {productData.data?.building_name && (
        <H3>{productData.data?.building_name} </H3>
      )}
      {productData.data?.meta_info?.capacity ? (
        <H3>{productData.data?.meta_info?.capacity} seater</H3>
      ) : (
        ''
      )}
      <FormWrapper
        config={formConfig}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default ProductPage;
