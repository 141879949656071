import { format } from 'date-fns';
import { Meta } from './types';

export const inferFieldType = (value: string) => {
  if (/^\d{8,10}$/.test(value)) {
    return 'tel';
  } else if (/^[A-Za-z0-9._%+-]+@/.test(value)) {
    return 'email';
  }
  return 'text';
};

export const validateEmailOrPhone = () => (value: string) => {
  const fieldType = inferFieldType(value);

  //   if (!types.includes(fieldType) && (required || value.length > 0))
  //     return 'Please enter valid input';
  if (fieldType === 'email') {
    if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(value)) {
      return 'Enter a valid email address';
    }
  } else if (fieldType === 'tel') {
    if (!/^\d{10}$/.test(value)) {
      return 'Enter a valid phone number';
    }
  }

  return true;
};

export function capitalizeFirstLetter(string: string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}

export function generateQueryString({ meta, data }: { meta: Meta; data: any }) {
  const queryParams: string[] = [];

  // Iterate over mappings object and append each key-value pair
  if (meta.mappings) {
    Object.keys(meta.mappings).forEach(key => {
      // console.log(key, data[key]);
      const mapping = meta.mappings![key];

      // Check if the current mapping matches ['startTime', 'endTime']
      const isStartTimeMapping =
        Array.isArray(mapping) &&
        JSON.stringify(mapping) === JSON.stringify(['startTime', 'endTime']);

      if (isStartTimeMapping) {
        const [startTime, endTime] = getStartAndEndTime(data[key]);
        queryParams.push(`startTime=${startTime}`);
        queryParams.push(`endTime=${endTime}`);
        return; // Exit early after processing startTime and endTime
      }

      // Handle other mappings for the current key
      mapping.forEach(field => {
        queryParams.push(`${meta.mappings![key]}=${data[key]}`);
      });
    });
  }

  //iterate overs mappings.params and append it to query params
  if (meta.params) {
    Object.keys(meta.params).forEach(key => {
      queryParams.push(`${key}=${meta.params![key]}`);
    });
  }

  // Construct the final URL
  const endpoint = meta.apiEndpoint;
  const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

  return endpoint + queryString;
}

const getStartAndEndTime = (date: Date | string) => {
  const bookingDate = new Date(date).toDateString();
  const selectedBookingDate = format(new Date(bookingDate), 'yyyy-MM-dd');
  const startTime = selectedBookingDate + 'T03:30:00.000Z';
  const endTime = selectedBookingDate + 'T11:30:00.000Z';
  return [startTime, endTime];
};

export const mandatoryGSTFields = [
  'Address Line 1',
  'city',
  'state',
  'pincode',
];

export const stateToStateCodes: Record<string, string> = {
  'Andhra Pradesh': 'AP',
  'Arunachal Pradesh': 'AR',
  Assam: 'AS',
  Bihar: 'BR',
  Chhattisgarh: 'CG',
  Goa: 'GA',
  Gujarat: 'GJ',
  Haryana: 'HR',
  'Himachal Pradesh': 'HP',
  'Jammu & Kashmir': 'JK',
  Jharkhand: 'JH',
  Karnataka: 'KA',
  Kerala: 'KL',
  'Madhya Pradesh': 'MP',
  Maharashtra: 'MH',
  Manipur: 'MN',
  Meghalaya: 'ML',
  Mizoram: 'MZ',
  Nagaland: 'NL',
  Odisha: 'OR',
  Punjab: 'PB',
  Rajasthan: 'RJ',
  Sikkim: 'SK',
  'Tamil Nadu': 'TN',
  Telangana: 'TG',
  Tripura: 'TR',
  Uttarakhand: 'UK',
  'Uttar Pradesh': 'UP',
  'West Bengal': 'WB',
  'Andaman & Nicobar Islands': 'AN',
  Chandigarh: 'CH',
  'Dadra and Nagar Haveli and Daman and Diu': 'DN',
  Delhi: 'DL',
  Lakshadweep: 'LD',
  Puducherry: 'PY',
  Ladakh: 'LA',
};
