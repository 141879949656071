import React, { ComponentType } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import Sidebar, {
  getLabelsByRole,
  getUserRole,
  sidebarRoute,
} from '../Sidebar';
import Loader from '../Loader';
import Navbar from '../Navbar';
import { SecureRoute, useOktaAuth } from '@okta/okta-react';

type Props = {
  exact?: boolean;
  component: ComponentType<any>;
  path: string;
};

const RouteWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  background-color: #fff;
`;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
`;

const SideSection = styled.aside`
  width: 15%;
  display: flex;
  flex-direction: row;
`;
const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
  margin-top: 3.5em;
`;

const ProtectedRoute = ({ component: Component, ...rest }: Props) => {
  const { tokenLoad, userInfo } = useAuthContext()!;
  const { authState } = useOktaAuth();

  const page = (
    <RouteWrapper>
      <SideSection>
        <Sidebar />
      </SideSection>
      <Page>
        <Navbar />
        <MainSection>
          <Component {...rest} />
        </MainSection>
      </Page>
    </RouteWrapper>
  );
  if (!authState || tokenLoad) return <Loader />;
  if (!userInfo) return <Redirect to="/login" />; // if user not logged in then lead to login page.

  //get type of user role
  const role = getUserRole(userInfo);

  // get all accessible routes for the user
  const routesAccessibleToUser = getLabelsByRole(role).map(
    route => sidebarRoute[route],
  );

  //if user is postpaid and trying to access postpaid products page, allow access
  if (
    userInfo.is_postpaid_user &&
    rest.path === '/shop/purchase-postpaid-products'
  )
    return <SecureRoute {...rest} render={() => page} />;

  //for all users only allow if the route is accessible to them
  if (userInfo && routesAccessibleToUser.includes(rest.path)) {
    return <SecureRoute {...rest} render={() => page} />;
  }

  // Check for routes with a common prefix in routesAccessibleToUser
  const accessibleRoutesWithCommonPrefix = routesAccessibleToUser.find(route =>
    rest.path.startsWith(route),
  );

  if (userInfo && accessibleRoutesWithCommonPrefix) {
    return <SecureRoute {...rest} render={() => page} />;
  }

  //based on user role, redirect to respective page - will be first among array of routes the user is able to access.
  const defaultRoute = routesAccessibleToUser[0];
  return (
    <SecureRoute {...rest} render={() => <Redirect to={defaultRoute} />} />
  );
};

export default ProtectedRoute;
