import FormInput from 'components/Form/FormInput';
import FormSelect from 'components/Form/FormSelect';
import { FORM_FIELD_TYPES } from 'components/Form/types';
import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import RayButton from 'components/RayButton';
import axios, { isCustomAxiosError } from 'utils/axios';
import { useOktaAuth } from '@okta/okta-react';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import { useQueryClient } from 'react-query';
import { InventoryItem } from './InventoryTable';
import { handleAxiosError } from 'utils/errorHelper';

const reasons = {
  'Sold Out': 'SOLD_OUT',
  Maintenance: 'MAINTENANCE',
  Holiday: 'HOLIDAY',
};
const options = Object.keys(reasons).map(reason => ({
  label: reason,
  value: reason,
}));
function getKeyFromValue(value: string): string | undefined {
  return Object.entries(reasons).find(([_, v]) => v === value)?.[0];
}

type Props = {
  index: number;
  item: InventoryItem;
  price?: number;
};

const InventoryRow = ({ index, item, price = 100 }: Props) => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const { authState } = useOktaAuth();
  const { setToastType } = useAuthContext()!;
  const queryClient = useQueryClient();

  const defaultValues = {
    [`rows[${index}].date`]: item.date,
    [`rows[${index}].increase_capacity`]: item.increased_capacity,
    [`rows[${index}].decrease_capacity`]: item.decreased_capacity,
    [`rows[${index}].reason`]: {
      label: item.reason ? getKeyFromValue(item.reason) : undefined,
      value: item.reason ? getKeyFromValue(item.reason) : undefined,
    },
  };

  const handleUpdate = async (data: any) => {
    setIsUpdating(true);
    const token = 'Bearer ' + authState?.accessToken?.accessToken;
    // Create payload only with changed values
    const payload: any = {
      inventory_uid: item.uid,
    };

    if (newAvailableCapacity < 0) {
      setToastType({
        show: true,
        type: 'error',
        message: 'Available capacity cannot be negative',
      });
      setIsUpdating(false);
      return;
    }

    if (
      Number(increaseCapacity) !== item.increased_capacity &&
      Number(decreaseCapacity) !== item.decreased_capacity
    ) {
      setToastType({
        show: true,
        type: 'error',
        message:
          'Increase and decrease capacity cannot be changed at the same time.',
      });
      setIsUpdating(false);
      return;
    }

    if (
      (newAvailableCapacity === 0 ||
        data.rows[index].available_capacity === 0) &&
      !data.rows[index].reason?.value
    ) {
      setToastType({
        show: true,
        type: 'error',
        message: 'Reason is required when available capacity is updated to 0',
      });
      setIsUpdating(false);
      return;
    }

    const newIncreaseCapacity = +data.rows[index].increase_capacity;
    const newDecreaseCapacity = +data.rows[index].decrease_capacity;

    if (newIncreaseCapacity !== item.increased_capacity) {
      payload.increased_capacity = newIncreaseCapacity;
    }

    if (newDecreaseCapacity !== item.decreased_capacity) {
      payload.decreased_capacity = newDecreaseCapacity;
    }

    if (
      data.rows[index].reason?.value &&
      data.rows[index].reason?.value !== item.reason
    ) {
      payload.reason = reasons[data.rows[index].reason?.value];
    }

    try {
      await axios.put(`/api/v1/admin/daywise-inventories`, payload, {
        headers: {
          Authorization: token,
        },
        baseURL: process.env.REACT_APP_CATALOGUE_BASE_URL,
      });

      setToastType({
        show: true,
        type: 'success',
        message: 'Inventory updated successfully',
      });

      //to retrigger inventory query
      queryClient.invalidateQueries(['inventory']);
    } catch (error) {
      console.error(error);
      if (isCustomAxiosError(error)) {
        const errorMessage = handleAxiosError(
          error,
          'Something went wrong when updating inventory',
        );
        setToastType({
          show: true,
          type: 'error',
          message: errorMessage,
        });
      }
    } finally {
      setIsUpdating(false);
    }
  };

  const form = useForm({ defaultValues });
  const { watch } = form;
  const [decreaseCapacity, increaseCapacity, reason]: [
    number,
    number,
    typeof options[0],
  ] = watch([
    `rows[${index}].decrease_capacity`,
    `rows[${index}].increase_capacity`,
    `rows[${index}].reason`,
  ]);

  // Calculate new available capacity and determine if it changed
  const newAvailableCapacity = useMemo(() => {
    const baseCapacity =
      item.available_capacity +
      (Number(increaseCapacity || 0) - item.increased_capacity) -
      (Number(decreaseCapacity || 0) - item.decreased_capacity);
    return baseCapacity;
  }, [
    item.available_capacity,
    increaseCapacity,
    decreaseCapacity,
    item.increased_capacity,
    item.decreased_capacity,
  ]);

  const hasCapacityChanged = newAvailableCapacity !== item.available_capacity;

  // Disable update button if no changes made
  const isDisabled =
    Number(increaseCapacity) === item.increased_capacity &&
    Number(decreaseCapacity) === item.decreased_capacity &&
    reason?.value === item.reason;

  return (
    <tbody key={index}>
      <tr>
        <FormProvider {...form}>
          <td style={{ textAlign: 'center' }}>{item.date}</td>
          <td style={{ textAlign: 'center' }}>
            {newAvailableCapacity}
            {hasCapacityChanged && (
              <div style={{ fontSize: '0.8em', color: '#856404' }}>
                Original: {item.available_capacity}
              </div>
            )}
          </td>
          <td>
            <FormInput
              label="Increase capacity"
              name={`rows[${index}].increase_capacity`}
              inputTextType="number"
              type={FORM_FIELD_TYPES.NUMBER}
              value={item.increased_capacity.toString()}
              fieldValidations={{
                min: {
                  value: item.increased_capacity,
                  message: `Value must be greater than ${item.increased_capacity}`,
                },
              }}
            />
          </td>
          <td>
            <FormInput
              label="Decrease capacity"
              name={`rows[${index}].decrease_capacity`}
              inputTextType="number"
              value={item.decreased_capacity.toString()}
              type={FORM_FIELD_TYPES.NUMBER}
              fieldValidations={{
                min: {
                  value: item.decreased_capacity,
                  message: `Value must be greater than ${item.decreased_capacity}`,
                },
              }}
            />
          </td>
          <td>
            <FormSelect
              options={options}
              type={FORM_FIELD_TYPES.SELECT}
              name={`rows[${index}].reason`}
              label="Reason"
              disabled={newAvailableCapacity !== 0}
            />
          </td>
          <td style={{ textAlign: 'center' }}>
            {/* price not editable */}₹{price}
          </td>

          <td style={{ textAlign: 'left' }}>
            <form onSubmit={form.handleSubmit(handleUpdate)}>
              <RayButton
                type="submit"
                buttonType="primary"
                disabled={isUpdating || isDisabled}
              >
                {isUpdating ? 'Updating...' : 'Update'}
              </RayButton>
            </form>
          </td>
        </FormProvider>
      </tr>
    </tbody>
  );
};

export default InventoryRow;
