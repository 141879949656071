import { ValidationRule } from 'react-hook-form';

export enum FORM_FIELD_TYPES {
  TEXT = 'text',
  EMAIL = 'email',
  PASSWORD = 'password',
  NUMBER = 'number',
  RADIO = 'radio',
  SELECT = 'select',
  MULTI_SELECT = 'multiselect',
  TEXT_AREA = 'textarea',
  FIELD_ARRAY = 'fieldarray',
  CHECKBOX = 'checkbox',
  SWITCH = 'switch',
  DATE_PICKER = 'datepicker',
  TIMEPICKER = 'timepicker',
  COUNTER = 'counter',
  MULTI_PRODUCT_INPUT = 'multiproductinput',
  GST_INPUT = 'gstinput',
}
export type OnChangeFunction = (...args: any[]) => void;

export type InputTextType = 'text' | 'number' | 'tel' | 'email' | 'url';

export interface BaseField {
  name: string;
  label: string;
  type: FORM_FIELD_TYPES;
  required?: boolean;
  fieldValidations?: FieldValidations;
  disabled?: boolean;
  key?: string;
  helperText?: string;
  toggle?: boolean;
}

export interface FormInputField extends BaseField {
  type:
    | FORM_FIELD_TYPES.TEXT
    | FORM_FIELD_TYPES.NUMBER
    | FORM_FIELD_TYPES.EMAIL;
  inputTextType: InputTextType;
  value?: string;
}

export interface FormDatePickerField extends BaseField {
  type: FORM_FIELD_TYPES.DATE_PICKER;
  shouldDisableWeekends?: boolean; // true only for conference rooms
}

export interface FormCounterField extends BaseField {
  type: FORM_FIELD_TYPES.COUNTER;
}

export interface SelectOption {
  label: string;
  value: string;
  id?: string;
}

export interface FormSelectField extends BaseField {
  type: FORM_FIELD_TYPES.SELECT;
  options?: SelectOption[];
  fetchOptions?: boolean;
  api_meta?: Meta;
}

export type Meta = {
  fieldsToWatch?: string[];
  apiEndpoint?: string;
  params?: any;
  mappings?: Record<string, string[]>;
  baseURL?: string;
  transform?: (data: any) => any;
  sanity?: (data: any) => any; // for sanity validation
};

export interface FormTextAreaField extends BaseField {
  type: FORM_FIELD_TYPES.TEXT_AREA;
  rows?: number;
}

export interface FormSwitchField extends BaseField {
  type: FORM_FIELD_TYPES.SWITCH;
  title?: string;
  heading?: string;
}

export interface FormGstInputField extends BaseField {
  type: FORM_FIELD_TYPES.GST_INPUT;
  fields: FormConfig[];
}

export interface FormMultiProductInputField extends BaseField {
  type: FORM_FIELD_TYPES.MULTI_PRODUCT_INPUT;
  meta?: { maxProducts: number; appendCTA: string };
  fieldArrayFields: FieldsArray[];
}

export interface FieldsArray {
  field1: FormConfig;
  field2: FormConfig;
}

export type FormConfig =
  | FormInputField
  | FormDatePickerField
  | FormCounterField
  | FormSelectField
  | FormTextAreaField
  | FormSwitchField
  | FormGstInputField
  | FormMultiProductInputField;

export type Validation = {
  key: string;
  type: string;
  value?: number;
  pattern?: string;
  message?: string;
};

type FieldValidations = {
  pattern?: ValidationRule<RegExp>;
  maxLength?: ValidationRule<number>;
  minLength?: ValidationRule<number>;
  required?: string | ValidationRule<boolean>;
  min?: ValidationRule<number>;
};
